import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import { motion } from 'framer-motion';
import Layout from '@components/Layout'
import SEO from '@components/seo'
import '@styles/pages/index.scss'
import HomeHero from '@components/Home/Hero/Hero'
import HomeProjects from '@components/Home/Projects'
import HomeExperimentalProjects from '@components/Home/Experimental Projects'
import HomeServices from '@components/Home/Services'
import HomeTechStack from '@components/Home/Tech Stack'
import HomeGetStarted from '@components/Home/Get Started'
import HomeGetInTouch from '@components/Home/Get in Touch'
import HomeTestimonials from '@components/Home/Testimonials'
import HomePhilosophy from '@components/Home/Philosophy'
import { useMainContext, MainContextConsumer } from '../components/LayoutContext';


const HomePage = (props) => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title;
  const [isPageLoaded, setPageLoaded] = useState(false);
  return (
    <Layout
      isHome
      location={props.location} 
      title={siteTitle}
      setPageLoaded={setPageLoaded}
      seo={
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
      }
      >
      {/* <motion.div
        initial={{ opacity: 0, y: 500 }}
        transition={{ duration: 1 }}
        animate={{
          opacity: isPageLoaded ? 1 : 0,
          y: isPageLoaded ? 0: 500,
        }}
      > */}
        <HomeHero />
        <HomeProjects />
        <HomeExperimentalProjects />
        <HomeServices />
        <HomeTechStack />
        <HomePhilosophy />
        <HomeTestimonials />
        {/* <HomeGetStarted /> */}
        <HomeGetInTouch />
      {/* </motion.div> */}
    </Layout>
  )
}

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
