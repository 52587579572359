import React, { useState } from "react";
import { motion } from 'framer-motion';
import BrowserDraggable from '@components/BrowserDraggable';
import './styles.scss';
import { MainContextConsumer, useMainContext } from '@components/LayoutContext';

import PlayImage from '@images/play.svg';
import ChoSpicer from '@images/cho-spicer.jpeg'
import SegmentedBarData from '@data/HomeSectionData.js';

const HomeTestimonials = () => {
  return (
    <section 
      className="home-testimonials-container" 
      data-scroll
      data-scroll-id={SegmentedBarData.testimonials.elementId}
      id={SegmentedBarData.testimonials.elementId}
    >
      <BrowserDraggable>
        <p className="home-testimonials-title">Some things are sweet and nice</p>
      </BrowserDraggable>
      <BrowserDraggable>
        <p className="home-testimonials-subtitle">We don’t like blowing our own trumpet; Read what other people have to say about us</p>
      </BrowserDraggable>
      <div className="home-testimonials-cards-container">
      <TestimonialTextCell
        image={ChoSpicer} 
        author="Cho Lee (CEO, SocietyGal)"
        comment="Working with Laagom 
has been a great experience. Unlike other developers I’ve worked with in the past. Their team operates like a business partner and trusted consultants. They were just as dedicated to business and went above and beyond to deliver your vision"
      />
        {/* <TestimonialCell 
          image={TestimonialPlaceholder}
          authorName="Rafella Mendes Diniz"
          authorDescription="Founder @Blah0"
          videoId="ScMzIvxBSi4"
        />
        <TestimonialCell 
          image={TestimonialPlaceholder}
          authorName="Bruce mars"
          authorDescription="Founder @Blah1"
          videoId="pVE92TNDwUk"
        /> */}
      </div>
    </section>
  )
}


const TestimonialTextCell = ({  image, author, comment }) => {
  return (
    <BrowserDraggable className="home-testimonial-card-container">
      <img 
        className="testimonial-text-author-image" 
        src={image}
        draggable={false}
      />
      <p className="testimonial-text-comment">{comment}</p>
      <p className="testimonial-text-author">- {author}</p>
    </BrowserDraggable>
  )
}

const TestimonialCell = ({ image, videoId, authorName, authorDescription }) => {
  const { state, dispatch } = useMainContext();
  return (
    <BrowserDraggable className="home-testimonial-card-container">
      <div className="testimonial-video-container">
        <img 
          className="testimonial-video-image"
          src={TestimonialPlaceholder}
          draggable={false}
        />
        <motion.div
          initial={{
            x: "-50%",
            y: "-50%",
            scale: 1.0,
          }}
          whileHover={{
            x: "-50%",
            y: "-50%",
            scale: 1.2,
          }}
          whileTap={{ 
            x: "-50%",
            y: "-50%",
            scale: 0.9,
          }}
          className="video-play-container"
          data-cursor-type="info"
          data-cursor-info="Play"
          onClick={() => {
            dispatch({type: 'play_video', payload: videoId})
          }}
        >
          <img src={PlayImage} draggable={false}/>
        </motion.div>
      </div>
      <div className="testimonial-author-container">
        <img 
          className="testimonial-author-image"
          src={image}
          draggable={false}
        />
        <div className="testimonial-author-info-container">
          <p className="testimonial-author-info-name">
            { authorName }
          </p>
          <p className="testimonial-author-info-description">
            { authorDescription }
          </p>
        </div>
      </div>
      
    </BrowserDraggable>
  )
}




export default React.memo(HomeTestimonials);