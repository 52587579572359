import React from 'react';
import { motion } from 'framer-motion';
import BlockBaseImage from '@images/laagom_block_base.png';
import BlockIphoneImage from '@images/laagom-block-iphone.png';
import BlockPinImage from '@images/laagom_block-pin.png';
import ForHumansImage from '@images/for_humans.png';
import BlockFullImage from '@images/laagom-block-full.png'
import './Home.scss';
import BrowserDraggable from '@components/BrowserDraggable';
import openCalendlyPopup from '@utils/openCalendlyPopup';
import { useMainContext } from '../../LayoutContext';

const HeroAnimationVariation = {
  initial: {
    opacity: 0,
    y: 500
  },
  loaded: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.3,
    },
  }
}

const HomeHero = () => {
  const { state } = useMainContext();
  const { isPageLoaded } = state;
  
  return (
    <motion.div
      data-scroll-call="home"
      variants={HeroAnimationVariation}
      initial="initial"
      animate={isPageLoaded ? "loaded" : "initial"}
      className="home-hero-container"
    >
      <FloatingBlock />
      <BrowserDraggable>
        <motion.div 
          className="hero-title-container"
          initial={{ y: 500 }}
          animate={{ y: isPageLoaded ? 0 : 500 }}
          transition={{ duration: 0.6, delay: 0.5 }}
        >
        Just the right amount, in <span className="design">designing</span>, <span className="develop">developing</span> and <span  className="building">building</span> effective digital spaces
          <img className="for-humans-image" src={ForHumansImage} draggable={false} alt="for humans"/>
        </motion.div>
      </BrowserDraggable>
      <BrowserDraggable>
        <motion.div
          className="meet-us-button"
          onClick={openCalendlyPopup}
          initial={{ y: 500 }}
          animate={{ y: isPageLoaded ? 0 : 500 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          >
          🤝 Meet us
        </motion.div>
      </BrowserDraggable>
    </motion.div>
  )

}

const FloatingBlock = () => (
  <>
  <div
      className="laagom-block-container"
    >
    <img
        className="laagom-block-base"
        src={BlockBaseImage}
        alt="block base"
        draggable={false}
        data-scroll 
        data-scroll-speed="-2"
      />
    <img
      className="laagom-block-iphone"
      src={BlockIphoneImage}
      alt="iphone"
      draggable={false}
      data-scroll 
      data-scroll-speed="1"
    />
    <img
      className="laagom-block-pin"
      src={BlockPinImage}
      alt="location pin"
      draggable={false}
      data-scroll 
      data-scroll-speed="4"
    />
  </div>
  <div className="laagom-block-full-container">
    <img
      src={BlockFullImage}
      alt="Colorful street block"
      draggable={false}
      data-scroll 
      data-scroll-speed="-1"
    />
  </div>
  </>
)

export default React.memo(HomeHero);