import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion'
import BrowserDraggable from '@components/BrowserDraggable';
import ZeeshImage from '../../../images/project-zeesh.png'
import BrideImage from '../../../images/project-bridge.png'
import BrandingImage from '../../../images/branding-image.png'
import DesignConceptImage from '../../../images/design-concept-image.png'
import './styles.scss';

import SegmentedBarData from '@data/HomeSectionData.js';

const HomeServices = () => {

  return (
    <section 
      data-scroll
      data-scroll-id={SegmentedBarData.services.elementId} 
      id={SegmentedBarData.services.elementId}
      className="home-services-container" 
      >
      <div className="wrapper">
        <BrowserDraggable>
          <p 
            className="home-services-title">
            Services we offer
          </p>
        </BrowserDraggable>

        <WebsiteAndPlatformsCard />
        <MobileApplicationsCard />
        <div className="home-service-h-service-wrapper">
          <StrategyAndBrandingCard />
          <DesignConceptCard />
        </div>
      </div>
    </section>
  )
}

const WebsiteAndPlatformsCard = () => {
    const [mouseHovered, setMouseHovered] = useState(false);
    const [cardTransform, setCardTransform] = useState({
      x: 0,
      y: 0,
    });

    function onMouseEnter() {
      setMouseHovered(true);
    };
  
    function onMouseLeave() {
      setMouseHovered(false);
    };

    function onMouseMove(e) {
        let xAxis = (window.innerWidth / 2 - e.pageX) / 25;
        let yAxis = (window.innerHeight / 2 - e.pageY) / 25;
        setCardTransform({
          x: xAxis,
          y: yAxis
        })
    };
    return (
    <BrowserDraggable>
      <div 
        className="home-services-website-card"
        onMouseMove={onMouseMove}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        >
        <div className="content">
            <p className="title">Websites and Platforms</p>
            <p className="description">Developing effortless, seamlessly navigational and aesthetically pleasing websites is important as it represents your company. We understand the significance and therefore, strive to help you make it the best. Website building is more than just marketing, as it is going to be your company’s virtual visiting card. Let’s build something amazing together!</p>
          </div>
          <div className="image-container">
            <motion.img
              transition={{ type: "spring", stiffness: 100 }}
              animate={{
                scale: mouseHovered ? 1.05 : 1,
                translateX: cardTransform.y, 
                translateY: cardTransform.x
              }}
              draggable={false}
              src={ZeeshImage}
              alt="Laptop with Zeesh website"
            />
        </div>
      </div>
    </BrowserDraggable>
  ) 
}


const MobileApplicationsCard = () => {
    const [mouseHovered, setMouseHovered] = useState(false);
    const [cardTransform, setCardTransform] = useState({
      x: 0,
      y: 0,
    });

    function onMouseEnter() {
      setMouseHovered(true);
    };
  
    function onMouseLeave() {
      setMouseHovered(false);
    };

    function onMouseMove(e) {
        let xAxis = (window.innerWidth / 2 - e.pageX) / 25;
        let yAxis = (window.innerHeight / 2 - e.pageY) / 25;
        setCardTransform({
          x: xAxis,
          y: yAxis
        })
    };
    return (
      <BrowserDraggable>
        <div 
        className="home-services-mobile-card"
        onMouseMove={onMouseMove}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        >
          <div className="content">
              <p className="title">Mobile Applications</p>
              <p className="description">Once a Patron, always a Patron; And so we work around the requirements of your targeted clientele and help you develop exclusive native applications. We completely devote ourselves to development and design, making it our top most priority. Our competitive advantage is that we create products that we know users will love.</p>
            </div>
            <div className="image-container">
              <motion.img
                transition={{ type: "spring", stiffness: 100 }}
                animate={{
                  scale: mouseHovered ? 1.05 : 1,
                  translateX: cardTransform.y, 
                  translateY: cardTransform.x
                }}
                draggable={false}
                src={BrideImage}
                alt="Bridge app on iPhone 12 Pro Max"
              />
          </div>
        </div>
    </BrowserDraggable>
  )
}

const StrategyAndBrandingCard = () => (
    <BrowserDraggable className="home-services-branding-card">
      <div className="content">
          <p className="title">Strategy and Branding</p>
          <p className="description">Our strategy and branding is simple, customer satisfaction. We sedulously work around this aspect to ensure utmost comfort for users. Therefore, we see that necessary information is established on the website or mobile application and is made easily perceivable.</p>
      </div>
      <img
        draggable={false}
        src={BrandingImage}
        alt="Laagom apps icons"
      />
    </BrowserDraggable>
)

const DesignConceptCard = () => (
  <BrowserDraggable className="home-services-design-concept-card">
    <div className="content">
        <p className="title">Design concept</p>
        <p className="description">A unique selling proposition is crucial for any business model and so we at Laagom, curate a design concept for your website or mobile application. Project budgets are discussed and a design concept apt for your budget is tailored. Rest assured, the conception will help you attract your audience and make your product even more astounding.</p>
    </div>
    <img
      draggable={false}
      src={DesignConceptImage}
      alt="harmony design system components"
    />
  </BrowserDraggable>
)

export default React.memo(HomeServices);
