import React, { useState, useEffect, useCallback } from "react";
import { motion } from 'framer-motion';
import BrowserDraggable from '@components/BrowserDraggable'
import './styles.scss';
import SegmentedBarData from '@data/HomeSectionData.js';
import { useMainContext } from '@components/LayoutContext';

import EffectiveFunctionalityImage from '../../../images/effective_functionality.jpeg';
import ProjectPreferenceImage from '../../../images/project_preference.jpeg';
import CustomerRelationsImage from '../../../images/customer_relations.jpeg';
import PlayImage from '@images/play.svg';


const PhilosophyData = [
  {
    id: 0,
    title: "Effective Functionality",
    subtitle: "We apply the Laagom approach to ensure better functionality both internally and externally in the company.",
    value: {
      type: 'video',
      src: EffectiveFunctionalityImage,
      videoId: "UhclKSeK_ZY"
    }
  },
  {
    id: 1,
    title: "Project Preference",
    subtitle: "The company lives by the motto \"Lagom är bäst\", meaning 'The right amount is the best'. Therefore, we work on limited projects to establish a good relationship with clients and maximise the efficacy the clients desire.",
    value: {
      type: 'image',
      src: ProjectPreferenceImage,
    }
  },
  {
    id: 2,
    title: "Customer relations",
    subtitle: "Laagom makes sure to specifically curate working standards in accordance to the requirements of the client and enhance customer relations with the company on the whole. Members of Team Laagom are acquainted to the functionalities within the departments of the company to ensure a smooth experience for the clients.",
    value: {
      type: 'image',
      src: CustomerRelationsImage
    }
  },
]

const slideShowInterval = 6000;

const HomePhilosophy = () => {

  const { state } = useMainContext();
  const [currentSlide, setCurrentSlide] = useState(-1);
  const [contentValue, setContentValue] = useState(PhilosophyData[0]);
  const [canAutoPlay, setCanAutoPlay] = useState(true);


  useEffect(() => {
    const { currentVisibleSection, openVideoModal } = state;
    const isCurrentSection = SegmentedBarData.philosophy.elementId === currentVisibleSection;
    const canAutoPlay = !openVideoModal && isCurrentSection
    setCanAutoPlay(canAutoPlay);
  }, [state]);

  useEffect(() => {
    if (currentSlide >= 0) {
      setContentValue(PhilosophyData[currentSlide])
    }
  }, [currentSlide]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!canAutoPlay) {
        return
      }
      let slide = (currentSlide + 1) % PhilosophyData.length
      setCurrentSlide(slide);
      console.log(slide, currentSlide, PhilosophyData.length);
    }, slideShowInterval);
    return () => clearTimeout(timer)
  }, [currentSlide, canAutoPlay])

  function onContentClick(content) {
    setContentValue(content);
    setCurrentSlide(content.id);
  }

  return (
    <BrowserDraggable>
      <section 
        className="home-philosophy-container"
        data-scroll
        data-scroll-id={SegmentedBarData.philosophy.elementId}
        id={SegmentedBarData.philosophy.elementId}
      >
        <p className="home-philosophy-title">Philosophy</p>
        <p className="home-philosophy-subtitle">Laagom is a Swedish and Norwegian word meaning 'Just the right amount'. We at Laagom work with this philosophy. Laagom offers you effective functionality, project preference and customer relations</p>

        <div className="home-philosophy-content-container">
          <div className="home-philosophy-content-menu">
            {
              PhilosophyData.map((data, index) => (
                <PhilosophyCell
                  key={index}
                  data={data}
                  currentValue={contentValue}
                  setContentValue={onContentClick}
                  // currentSlideProgress={currentSlideProgress}
                />
              ))
            }
          </div>
          <PhilosophyContentValue value={contentValue.value} />
        </div>
      </section>
    </BrowserDraggable>
  )
}

const PhilosophyCell = ({
  key,
  data,
  setContentValue,
  currentValue,
  // currentSlideProgress,
}) => {
  const isSelected = currentValue?.id === data.id || false;

  return (
    <motion.div
      animate={{
        backgroundColor: isSelected ? '#2B5675' : 'transparent',
        opacity: isSelected ? 1 : 0.5,
        boxShadow: isSelected ? '1px 2px 40px rgba(0, 0, 0, 0.08)' : '',
      }}
      key={key}
      className="philosophy-cell-container"
      onClick={() => setContentValue(data)}
    >
      <p className="title">{data.title}</p>
      <p className="subtitle">{data.subtitle}</p>
    </motion.div>
  )
}

const PhilosophyContentValue = ({ value }) => {
  
  let content = null;

  if(value?.type === "image") {
    content = <PhilosophyCellValueImage src={value.src}/>;
  } else if (value?.type === "video") {
    content = <PhilosophyCellValueVideo 
      src={value.src} 
      videoId={value.videoId}
    />
  }

  return (
    <div className="home-philosophy-content-value">
      {content}
    </div>
  )
}

const PhilosophyCellValueImage = ({ src }) => {
  return (
    <img className="cell-value-image"  src={src} draggable={false}/>
  )
}

const PhilosophyCellValueVideo = ({ src, videoId }) => {
  const { dispatch } = useMainContext();
  return (
    <div className="cell-value-video-container">
        <img 
          className="cell-value-video-image"
          src={src}
          draggable={false}
        />
        <motion.div
          initial={{
            x: "-50%",
            y: "-50%",
            scale: 1.0,
          }}
          whileHover={{
            x: "-50%",
            y: "-50%",
            scale: 1.2,
          }}
          whileTap={{ 
            x: "-50%",
            y: "-50%",
            scale: 0.9,
          }}
          className="cell-value-play-container"
          data-cursor-type="info"
          data-cursor-info="Play"
          onClick={() => {
            dispatch({type: 'play_video', payload: videoId})
          }}
        >
          <img src={PlayImage} draggable={false}/>
        </motion.div>
      </div>
  )
}

export default React.memo(HomePhilosophy);