import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './styles.scss'
import BrowserDraggable from '@components/BrowserDraggable';
import ArrowUpRight from '@images/arrow-up-right.svg'
import HarmonyImage from '@images/project-harmony-mockup.png'
import KlipKutterImage from '@images/project-klipkutter.png'
import FacepassImage from '@images/project-facepass.png'
import HazeOSImage from '@images/project-hazeos.png'
import VenturiousImage from '@images/project-venturious.png'
import openInNewTab from '@utils/openInNewTab';
import { useMainContext } from '../../LayoutContext';

const HomeExperimentalProjects = () => {
  const { dispatch } = useMainContext();
  return (
    <div className="home-experimental-projects-container">
      <BrowserDraggable>
        <p 
          className="title">
          Experimental / Side projects
        </p>
      </BrowserDraggable>

      <div className="project-cell-container">
        <ExperimentalProjectCell 
          title="Harmony design system"
          description="A minimalistic design system developed for React apps/web apps. Designed to be reused for our future client apps and the original nocode tool."
          image={HarmonyImage}
          onClick={() => openInNewTab("https://github.com/devwaseem/Harmony_react_native")}
        />
        <ExperimentalProjectCell 
          title="Klip Kutter"
          description="Klipp Kutter is a video editing mobile application. Klip Kutter cuts long videos into a series of 30 seconds clips making it suitable to be shared on social media platforms like- Instagram, Facebook and WhatsApp. It has a minimalistic UX and supports sharing to all social media platforms."
          image={KlipKutterImage}
          onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=com.waseem.videotrimmerforwhatsapp&hl=en_IN&gl=US")}
        />
        <ExperimentalProjectCell 
          title="Facepass"
          description="Facepass is a social networking platform used to send anonymous messages to friends using facial recognition. Users can scan, read/write comments on their friend’s public wall. Facepass is compatible with all devices as the recognition is powered by OpenCV Library."
          image={FacepassImage}
          onClick={() => openInNewTab("https://github.com/devwaseem/FacePass")}
        />
        <ExperimentalProjectCell 
          title="Venturious"
          description="Venturious is a social directory which helps connect like-minded people or people looking for contributors to work on their startups or collaborators for a joint venture. Users can post information about their venture and make it available for others to view. Interested parties can get in touch via call or through their social media handle. This mobile application is a must-have for investors looking to invest in startups."
          image={VenturiousImage}
          onClick={() => openInNewTab("https://iphone.apkpure.com/venturious/com.iapp.venturiouscorp.venturious")}
        />
        <ExperimentalProjectCell 
          title="Haze OS"
          description=" Haze OS is a user-friendly Linux distribution. Haze OS has an elegant design and easy to use interface ensuring a plain sailing computing experience. Haze OS is primarily aimed at beginners. However, it has powered many Open-Source technologies around the world."
          image={HazeOSImage}
          link="https://youtu.be/hidDmnSVI0Q"
          onClick={() => dispatch({ type: 'play_video', payload: "hidDmnSVI0Q" })}
        />
      </div>
    </div>
  )

}


const ExperimentalProjectCell = ({
  title,
  description,
  image,
  hint="Open project",
  onClick,
}) => {


  const [isMouseHovered, setMouseHovered] = useState(false);

  return (
    <BrowserDraggable>
      <div 
        className="project-cell"
        data-cursor-type="info"
        data-cursor-info={hint}
        onMouseEnter={() => setMouseHovered(true)}
        onMouseLeave={() => setMouseHovered(false)}
        onClick={onClick}
      >
        <p className="title">{title}</p>
        <p className="description">{description}</p>
        <img draggable={false} className="arrow" src={ArrowUpRight} alt="open link"/>
        {
            image && <motion.img
            animate={{ opacity: isMouseHovered ? 1 : 0 }}
            draggable={false} 
            className="project-image" 
            src={image}
            alt={title + 'image'}
          />
          }
      </div>
    </BrowserDraggable>
  )
}

export default React.memo(HomeExperimentalProjects);