import React from "react";
import { motion } from 'framer-motion';
import BrowserDraggable from '@components/BrowserDraggable';
import TechStackImage from '../../../images/tech-stack-image.png'
import './styles.scss'

import AndroidStudioImage from '../../../images/techstack/androidstudio.png'
import AWSImage from '../../../images/techstack/aws.png'
import BubbleImage from '../../../images/techstack/bubble.png'
import CSSImage from '../../../images/techstack/css.png'
import FigmaImage from '../../../images/techstack/figma.png'
import FirebaseImage from '../../../images/techstack/firebase.png'
import GithubImage from '../../../images/techstack/github.png'
import GlideImage from '../../../images/techstack/glide.png'
import HTMLImage from '../../../images/techstack/html.png'
import JSImage from '../../../images/techstack/javascript.png'
import KotlinImage from '../../../images/techstack/kotlin.png'
import MongoDbImage from '../../../images/techstack/mongodb.png'
import NetlifyImage from '../../../images/techstack/netlify.png'
import NodejsImage from '../../../images/techstack/nodejs.png'
import ReactImage from '../../../images/techstack/react.png'
import SwiftImage from '../../../images/techstack/swift.png'
import SwiftUIImage from '../../../images/techstack/swiftui.png'
import VSCodeImage from '../../../images/techstack/vscode.png'
import WebflowImage from '../../../images/techstack/webflow.png'
import XcodeImage from '../../../images/techstack/xcode.png'


const techStackList = [
  {
    image: AndroidStudioImage,
    name: "Android Studio"
  },
  {
    image: AWSImage,
    name: "AWS"
  },
  {
    image: BubbleImage,
    name: "Bubble.io"
  },
  {
    image: CSSImage,
    name: "CSS"
  },
  {
    image: FigmaImage,
    name: "Figma"
  },
  {
    image: FirebaseImage,
    name: "Firebase"
  },
  {
    image: GithubImage,
    name: "Github"
  },
  {
    image: GlideImage,
    name: "Glide apps"
  },
  {
    image: HTMLImage,
    name: "HTML"
  },
  {
    image: JSImage,
    name: "Javascript"
  },
  {
    image: KotlinImage,
    name: "Kotlin"
  },
  {
    image: MongoDbImage,
    name: "MongoDB"
  },
  {
    image: NetlifyImage,
    name: "Netlify"
  },
  {
    image: NodejsImage,
    name: "NodeJS"
  },
  {
    image: ReactImage,
    name: "React & React Native"
  },
  {
    image: SwiftImage,
    name: "Swift"
  },
  {
    image: SwiftUIImage,
    name: "SwiftUI"
  },
  {
    image: VSCodeImage,
    name: "VSCode"
  },
  {
    image: WebflowImage,
    name: "Webflow"
  },
  {
    image: XcodeImage,
    name: "Xcode"
  },
]

const HomeTechStack = () => {
  return (
    <section className="home-tech-stack-container">
      <BrowserDraggable>
        <img
          src={TechStackImage}
          alt="our tech stack"
          className="home-tech-stack-image"
          draggable={false}
        />
      </BrowserDraggable>
      <div className="home-tech-stack-grid">
        {
          techStackList.map((obj) => (
            <BrowserDraggable>
              <img 
                src={obj.image}
                alt={obj.name}
                className="tech-stack-image"
                draggable={false}
                data-cursor-type="info"
                data-cursor-info={obj.name}
              />
            </BrowserDraggable>
          ))
        }
      </div>
    </section>
  )
}

export default React.memo(HomeTechStack);