import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import { motion } from 'framer-motion';
import BrowserDraggable from '@components/BrowserDraggable';
import SocietyGalImage from '../../../images/project-societygal.png'
import ZimmerAllImage from '../../../images/project-zimmer2.png'
import StashlyImage from '../../../images/project-stashly.png'
import ZeeshImage from '../../../images/project-zeesh.png'
import BridgeImage from '../../../images/project-bridge.png'
import classNames from 'classnames';
import './styles.scss';

import SegmentedBarData from '@data/HomeSectionData.js';


const HomeProjects = () => {

  return (
    <section 
      className="home-project-container" 
      data-scroll
      data-scroll-id={SegmentedBarData.projects.elementId}
      id={SegmentedBarData.projects.elementId}
      >
      <BrowserDraggable>
        <p className="home-project-title">
          Simple and impeccable <span>Digital products</span> for entrepreneurs, ventures, communities aimed at delivering wonderful UX
        </p>
      </BrowserDraggable>      
      <div className="home-project-projectContainer">
        <ProjectCard
          title="SocietyGal"
          subtitle="SocietygGal is a female-focused marketplace where brands, freelancers, and creatives connect. With SocietyGal users can collaborate, host events and exchange goods. The company focuses on empowering women professionally through different walks of life."
          background="#FCEBE5"
          image={SocietyGalImage}
          tags={["Mobile App", "Branding", "User interface design"]}
          link="societygal"
        />
        <ProjectCard
          title="Zimmer Sports"
          subtitle="Zimmer Sports is your virtual adventure finder. With Zimmer Sports you can find unique spots to visit and amuse yourself with various available adventure sports. The site offers flawless user experience making the booking process convenient."
          background="#C2ECFF"
          image={ZimmerAllImage}
          tags={["Progressive Web App", "Web Admin portal", "UI/UX Design" ,"Branding"]}
          link="zimmer"
        />
        <ProjectCard
          title="Stashly"
          subtitle="Stashly is an online vanity which helps users keep a track of their makeup, purchases and exclusively mentions expenditure on products with dates. With Stashly users can share pictures, videos and graphic texts on a Social Feed. This is godsend for Fashion influencers!"
          background="#4CD8CF"
          image={StashlyImage}
          tags={["Mobile App Development", "UI/UX Design", "Admin portal", "Branding"]}
          link="stashly"
        />
        <ProjectCard
          title="Zeesh"
          subtitle=" Zeesh is a luxury sneaker and accessories brand. Zeesh is committed towards the growth of India and making sure to make its name among leading shoemakers in the world. Every product is crafted with excellent craftsmanship to ensure patronage satisfaction."
          background="#FF8F00"
          image={ZeeshImage}
          tags={["Web App Development", "UI/UX Design", "e-commerce"]}
          link="zeesh"
        />
        <ProjectCard
          title="Bridge"
          subtitle="Bridge is a mobile application aimed at functioning as an online job marketplace"
          background="#13C6FF"
          image={BridgeImage}
          tags={["Mobile Application", "Web Admin portal", "Branding"]}
          hint="Coming soon..."
          link="bridge"
        />
      </div>
    </section>
  )

}

const ProjectCard = ({ 
  image,
  title,
  subtitle,
  tags,
  background,
  hint="Open case study",
  inverse=false,
  link="",
}) => {
  const [mouseHovered, setMouseHovered] = useState(false);
  const [cardTransform, setCardTransform] = useState({
    x: 0,
    y: 0,
  });

    function onMouseEnter() {
      setMouseHovered(true);
    };
  
    function onMouseLeave() {
      setMouseHovered(false);
    };

    function onMouseMove(e) {
        let xAxis = (window.innerWidth / 2 - e.pageX) / 25;
        let yAxis = (window.innerHeight / 2 - e.pageY) / 25;
        setCardTransform({
          x: xAxis,
          y: yAxis
        })
    };

    const cardTitleClassnames = classNames(
      "project-card-title",
      {
        "inverse-text-color": inverse
      }
    )

    const cardSubTitleClassnames = classNames(
      "project-card-subtitle",
      {
        "inverse-text-color": inverse
      }
    )

    const cardTagClassnames = classNames(
      "project-card-tag",
      {
        "inverse-tag": inverse
      }
    )

  return (
    <BrowserDraggable>
    <div
      className="project-card-container"
      data-cursor-type="info"
      data-cursor-info={hint}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => navigate(link)}
      >
      <div 
        className="project-card-background"
        style={{ background }}
      >
        <div className="project-card-content">
          <p className={cardTitleClassnames}>{title}</p>
          <p className={cardSubTitleClassnames}>{subtitle}</p>
          <div className="project-card-tag-container">
            {
              tags &&
              tags.map((tag) => (
                <p className={cardTagClassnames}>{tag}</p>
              ))
            }
          </div>
        </div>

        <div className="project-card-image-container">
            <motion.img 
              transition={{ type: "spring", stiffness: 100 }}
              animate={{
                scale: mouseHovered ? 1.05 : 1,
                translateX: cardTransform.y, 
                translateY: cardTransform.x
              }}
              src={image} 
              alt={title + 'image'}
              draggable={false}
              className="project-card-image"
            />
        </div>
      </div>
    </div>
    </BrowserDraggable>
  )

};

export default React.memo(HomeProjects);