import React from 'react';
import BrowserDraggable from '@components/BrowserDraggable';
import CalendarImage from '@images/calendar-white.svg';
import openCalendlyPopup from '@utils/openCalendlyPopup';
import './index.scss';

const HomeGetInTouch = () => {
  return (
    <section className="home-get-in-touch-container" id="get-in-touch">
      <BrowserDraggable>
        <p className="home-get-in-touch-title">Connect with us today!</p>
      </BrowserDraggable>
      
      <BrowserDraggable>
        <p className="home-get-in-touch-description">Brief us your plan and we’ll help you bring it to reality</p>
      </BrowserDraggable>

      <BrowserDraggable>
        <div 
          className="home-get-in-touch-book-button" 
          onClick={openCalendlyPopup}>
          <img src={CalendarImage} draggable={false} />
          <span>Book a free consultation</span>
        </div>
      </BrowserDraggable>
    </section>
  )
}

export default HomeGetInTouch;